import styled from 'styled-components';
import dynamic from 'next/dynamic';
// import MediaQuery from 'react-responsive';
// import Link from 'next/link';
// import { WEB_NAME } from 'src/constants/layout';
import SearchBox from 'src/components/search-box';
import { useRouter } from 'next/router';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import WidgetLogin from 'src/services/member-auth/components/widget-login';
import { SHOW_MEMBER } from 'src/services/member-auth/constants';
import { APP_IMG } from 'src/constants';

const NavMobile = dynamic(import('src/components/layouts/nav-mobile'));
const NavDesktop = dynamic(import('src/components/layouts/nav-desktop'));

const Header = ({ data, sessionSevId }) => {
  const router = useRouter();
  return (
    <>
      <HeaderComponent>
        <div className='logo-header'>
          <div className='container header-top'>
            <div className='show-mobile'>
              <NavMobile data={data} />
            </div>
            {router.asPath === '/' ? (
              <>
                <div>
                  <h1>
                    <a
                      aria-label='logo'
                      title='logo'
                      href='/'
                      //! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          position: 'on:header',
                          section: 'header',
                          data: {
                            title: 'logo',
                            heading: 'logo'
                          }
                        })
                      }
                    >
                      <span>ขอบสนาม - ข่าวฟุตบอล บอลวันนี้ คลิปบอล ผลบอลสด บอลไทย พรีเมียร์ลีก</span>
                      <img className='logo-img' src={`${APP_IMG}/images/apple-touch-icon.png`} loading='lazy' alt='logo-heading' title='logo-heading' width='48' height='48' />
                    </a>
                  </h1>
                  <h2>
                    <span>ขอบสนาม - ข่าวฟุตบอล ข่าวทั้งหมด</span>
                  </h2>
                </div>
              </>
            ) : (
              <a
                aria-label='logo'
                title='logo'
                href='/'
                //! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK_POSITION,
                    router: router?.pathname,
                    position: 'on:header',
                    section: 'header',
                    data: {
                      title: 'logo',
                      heading: 'logo'
                    }
                  })
                }
              >
                <img className='logo-img' src={`${APP_IMG}/images/apple-touch-icon.png`} loading='lazy' alt='logo-heading' title='logo-heading' width='48' height='48' />
              </a>
            )}
            <div className='right'>
              <SearchBox />
              {SHOW_MEMBER === 'true' && <WidgetLogin sessionSevId={sessionSevId} />}
            </div>
          </div>
        </div>
        <div className='show-desktop'>
          <NavDesktop data={data} />
        </div>
      </HeaderComponent>
    </>
  );
};

const HeaderComponent = styled.header`
  position: sticky;
  top: 0;
  z-index: 10;
  height: 150px;
  width: 100%;

  @media (max-width: 1024px) {
    height: 70px;
  }

  .show-desktop {
    display: block;
    overflow-x: hidden;
  }

  .show-mobile {
    display: none;
  }

  @media (max-width: 1024px) {
    .show-desktop {
      display: none;
    }
    .show-mobile {
      display: block;
      overflow-x: hidden;
    }
  }

  .logo-header {
    display: flex;
    justify-content: center;
    background-color: #010b2b;
    box-shadow: rgba(0, 255, 87, 0.5) 0px 12px 10px -10px;
    height: 70px;

    .header-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* position: relative; */
      /* overflow: hidden; */
      h1,
      h2 {
        margin: 0;
        overflow: hidden;
        position: relative;
        span {
          text-indent: 1000em;
          white-space: nowrap;
          overflow: hidden;
          display: block;
          position: absolute;
          width: 100%;
        }
      }
    }
    .logo-img {
      display: block;
      width: auto;
      @media (max-width: 768px) {
        padding-left: 15px;
      }
    }
    .btn-search {
      display: flex;
      align-items: center;
      justify-content: center;
      .form-group {
        padding: 5px 10px;
        border: 2px solid #00ff57;
        transform: skewX(-18deg);
        border-radius: 3px;
      }
      input {
        border: none;
        font-size: 16px;
        background-color: transparent;
        transform: skewX(18deg);
        &:focus,
        &:active {
          outline: none;
        }
      }
      svg {
        color: #00ff57;
        font-size: 20px;
        vertical-align: middle;
        transform: skewX(18deg);
      }
      .text-search {
        color: #00ff57;
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #00ff57;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #00ff57;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #00ff57;
        }
      }
    }
  }
  .right {
    display: flex;
  }
  .menu {
    margin: auto;
    .border-bottom {
      border-bottom: 1px solid rgb(243 243 243);
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    }
    .unset-boder {
      border: unset !important;
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      list-style-type: none;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
      padding-inline-start: 0;
      li:nth-child(1) {
        margin-left: 0;
      }
      li {
        margin-left: 15px;
        padding: 10px 20px 10px 20px;
        width: 100%;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 2px;
        cursor: pointer;
        &.--active {
          /* border:1px solid #00ff57;
          background-color: #00ff57 !important; */
          /* &:hover { */
          a span,
          svg {
            color: rgba(0, 170, 44);
            /* color : rgba(0,2,28, 0.95); */
          }
          /* } */
        }
        &:hover {
          /* border: 1px solid #00ff57; */
          a span,
          svg {
            color: rgba(0, 170, 44);
          }
        }
        a {
          font-size: 16px;
        }
      }
    }
  }

  ul.nav-menu {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    padding-inline-start: 0;
    padding: 0 20px;
    margin: 0px;

    li.active-menu {
      margin: 0;
      border-top: 1px solid #00ff57;
      .d-flex {
        justify-content: space-between;
        align-items: center;
      }
      &:hover {
        .d-flex {
          span {
            color: #00ff57;
          }
        }
        .drop-item {
          color: #00ff57;
        }
      }
      .drop-item {
        color: #fff;
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        &:active,
        &:focus {
          outline: none;
          border: none;
        }
      }
    }
    li.active-menu:first-child {
      border: none;
    }

    .list-menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      transition: all 0.3s ease-out;

      .item-menu {
        padding: 15px 0px;
        color: #fff;
        display: flex;
        width: 100%;
        overflow-x: hidden;
        justify-content: space-between;
        align-items: center;

        text-transform: capitalize;
        transition: all 0.5s ease-out;

        &:active,
        &:focus,
        &:hover {
          color: #00ff57;
        }
        span {
          text-transform: uppercase;
          font-weight: 600;
          margin: 0px;
          padding-left: 20px;
          white-space: pre;
        }

        svg {
          margin-right: 20px;
          transition: all 0.5s ease-out;
          width: 20px;
          height: 20px;
        }
      }

      a {
        color: #fff;
      }
    }
  }

  /* ---------------------------- MOBILE : Navigation SubMenu ------------------------------ */
  ul.nav-sub-menu {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: none;
    border: none;
    top: 55.5px;
    li.active-sub-menu {
      width: 100%;
      left: 3em;

      border-top: 1px solid #00ff57;
      text-transform: capitalize;
      transition: all 0.5s ease-out;

      a {
        text-transform: uppercase;
        font-weight: 500;

        .list-sub-menu {
          padding: 1em 10%;
          svg,
          span.sub-menu {
            color: white !important;
          }
        }
      }

      &:hover,
      &:focus,
      &.active {
        background-color: #008b2e;
      }
    }
  }
`;

export default Header;
