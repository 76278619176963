import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import MediaQuery from 'react-responsive';

const WidgetSearchFullPage = dynamic(import('src/components/widgets/widget-search-full-page'), { ssr: false });

const SearchBox = ({ ...props }) => {
  const [searchToggle, setSearchToggle] = useState(false);

  function handleToggle (_key) {
    setSearchToggle(!searchToggle);
  }

  return (
    <>
      <MediaQuery maxWidth={1024}>
        <ButtonBase id='search-box' aria-label='right-align' onClick={() => handleToggle()} {...props}>
            <FaSearch />
        </ButtonBase>
      </MediaQuery>
      <WidgetSearchFullPage
        heading='Search Page'
        toggle={searchToggle}
        handle={handleToggle}
      />
    </>
  );
};

const ButtonBase = styled.button`
  display: block;
  outline: none;
  background: none;
  border-style: unset;
  cursor: pointer;
  svg {
    /* margin: .70rem; */
    color: #00ff57; 
    font-size: 20px;
    /* @media (max-width: 786px){
      margin: .5rem;
    } */
  }
`;

export default SearchBox;
