/* eslint-disable multiline-ternary */
import _ from 'lodash';
import { useState } from 'react';

import CheckboxForm from 'src/services/member-auth/components/forms/checkbox-form';
import Cookies from 'js-cookie';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { WidgetLoginAuthO as WidgetLogin2 } from '@nationgroup/nation-login-widgets';
import { APP_URL, NEXT_PUBLIC_LOGIN_STATE_DATE } from 'src/constants';

function WidgetLogin({ sessionSevId }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [checkPDPA, setCheckPDPA] = useState(false);
  const [checkCookies, setCheckCookies] = useState(false);
  const router = useRouter();

  const conditionDisabled = Cookies.get('login') === 'success' ? false : !(checkPDPA && checkCookies);
  const nid = Cookies.get('_nid');
  const ga = Cookies.get('_ga');
  const _key = nid ? `${ga} : ${nid}` : `${ga}`;
  return (
    <WidgetWrapper id='widget-login'>
      <WidgetLogin2
        className='widget-login'
        sendDataLayerGlobal={sendDataLayerGlobal}
        DATALAYER_TYPE={DATALAYER_TYPE}
        conditionDisabled={conditionDisabled}
        error={error}
        sessionSevId={sessionSevId}
        open={open}
        setOpen={setOpen}
        setError={setError}
        router={router}
        _key={_key}
        nextAuthUrl={APP_URL}
        isRemoveCookie={true}
        loginStateExpire={Number(NEXT_PUBLIC_LOGIN_STATE_DATE)}
      >
        <CheckboxForm name='pdpaConsent' label={'นโยบายคุ้มครองข้อมูลส่วนบุคคล'} checked={checkPDPA} onChange={e => setCheckPDPA(e.target.checked)} />
        <CheckboxForm name='cookieConsent' label={'นโยบายคุ้มครองข้อมูลการใช้คุกกี้'} checked={checkCookies} onChange={e => setCheckCookies(e.target.checked)} />
      </WidgetLogin2>
    </WidgetWrapper>
  );
}
const WidgetWrapper = styled.div`
  #widget-login {
    position: relative;
    display: block;
    padding-left: 10px;
    /* padding: 8px 0 8px 16px;
    @media (max-width: 768px) {
      padding: 8px 10px 8px 16px;
    } */
    &:hover {
      .menu-list {
        display: flex;
      }
      .btn-login {
        svg {
          color: #696969;
        }
      }
    }

    .link-policy {
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      color: #bfbfbf;
      &:hover {
        color: #696969;
      }
    }
    /* ------------------------------ BUTTON LOGIN ------------------------------ */
    .btn-login {
      max-width: 40px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      padding: 0;
      border: none;
      border-radius: 50px;
      &.login {
        border: 2px solid #fff;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.16));
      }

      svg {
        color: #00ff57;
        height: 32px;
        width: 32px;
        @media (max-width: 1024px) {
          height: 24px;
          width: 24px;
        }
        @media (max-width: 380px) {
          height: 22px;
          width: 22px;
        }
      }
      .image-user {
        overflow: hidden;
        margin: 0px;
        padding: 0px;
        border-radius: 50px;
        object-fit: cover;
        width: 26px;
        height: 26px;
      }
      @media (min-width: 1440px) {
        margin: 4px 0 0 12px;
        .image-user {
          width: 32px;
          height: 32px;
        }
      }
    }

    .info {
      font-size: 12px;
      font-weight: 300;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      .username,
      .email {
        width: 150px;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;

        padding: 5px;
        margin: 0;
      }
    }
    .error-label {
      font-size: 12px;
      color: red;
    }
    .menu-list {
      z-index: 24;
      display: none;
      position: absolute;
      top: 48px;
      right: 0;
      background-color: #ffffff;
      box-shadow: 1px 0px 10px 0px rgba(46, 46, 46, 0.3);
      width: max-content;
      flex-direction: column;
      border-radius: 5px;
      margin: 0;
      padding: 10px !important;
      &:before {
        content: '';
        position: absolute;
        top: -8px;
        right: 0px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid white;
      }
      @media (max-width: 1440px) {
        top: 48px;
      }
      @media (max-width: 767px) {
        left: auto;
        right: -10px;
        top: 38px;
        &:before {
          top: -5px;
          right: 10px;
          left: auto;
        }
      }
      @media (max-width: 475px) {
        right: 0;
        top: 45px;
      }
      .list-item {
        width: 100%;
        margin: 0;
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          svg {
            margin-right: 5px;
          }
        }
      }

      svg {
        color: #010b2b;
        font-weight: 700;
        margin-left: 5px;
        margin-bottom: 2px;
        transition: all 3ms ease-in;
      }
      li {
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        padding: 8px;
        font-family: 'Prompt', sans-serif;
        gap: 5px;
        z-index: 2;
        border-radius: 5px;
        p {
          padding: 0;
          margin: 0;
          color: #010b2b;
        }
        &:hover {
          box-shadow: 1px 0px 10px 0px rgba(2, 63, 135, 0.3);
          background-color: #010b2b;
          p,
          svg {
            color: #00ff57;
          }
        }
      }
    }
  }
  #widget-social {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 15;
    .bottom-line {
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.15);
    }
    .modal-social {
      background: linear-gradient(103.43deg, #f6f6f6 0%, #eeeeee 100%);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.85);
      border-radius: 10px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: 16px;
      padding: 40px 30px;

      @media (max-width: 767px) {
        padding: 20px;
        width: 320px;
      }
      @media (max-width: 380px) {
        width: 300px;
      }

      .heading {
        color: #3ace01;
        font-size: 26px;
        font-weight: 900;
        display: block;
        text-align: center;
        margin-bottom: 10px;
        background-color: unset;
        width: 100%;
        border-radius: unset;
        box-shadow: unset;
        /* border-bottom: 1px solid#010b2b; */
        /* padding-bottom: 15px; */
        @media (max-width: 767px) {
          font-size: 24px;
        }
        @media (max-width: 380px) {
          margin-top: 10px;
          margin-bottom: 5px;
        }
      }
      /* ------------------------------ BUTTON CLOSE ----------------------------- */
      .btn-close {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        transition: all ease 0.25ms;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
          color: #3ace01;
        }
        @media (max-width: 767px) {
          top: 10px;
        }

        &:hover {
          color: #474747;
        }
      }
      /* ------------------------------ BUTTON SOCIAL ----------------------------- */
      .btn-social {
        cursor: pointer;
        transition: background-color ease-in-out 100ms;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.08)) drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.16));
        background-color: #ffffff;
        border-radius: 50px;
        color: #fff;
        border: 0;

        gap: 16px;
        padding: 16px 30px;

        width: 100%;
        max-width: 345px;
        margin: auto;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 767px) {
          padding: 16px;
        }
        p {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.25;
          color: #111;

          span {
            text-transform: capitalize;
          }
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        &.icon-google {
          color: #000000;
          background: #ffffff;
          &.disabled {
            background: #e5e5e5;
          }
          &:hover {
            background: #e5e5e5;
            color: #ffffff;
          }
        }
        &.icon-facebook {
          background: #ffffff;
          &.disabled {
            background: #e5e5e5;
          }
          svg {
            color: #337fff;
          }
          &:hover {
            background: #e5e5e5;
          }
        }
        &.icon-line {
          background: #ffffff;
          &.disabled {
            background: #e5e5e5;
          }
          svg {
            color: #3ace01;
          }
          &:hover {
            background: #e5e5e5;
          }
        }
        &.icon-tiktok {
          background: #ffffff;
          &.disabled {
            background: #e5e5e5;
          }
          svg {
            color: #000;
          }
          &:hover {
            background: #e5e5e5;
          }
        }
        &.icon-linkin {
          background: #ffffff;
          &.disabled {
            background: #e5e5e5;
          }
          svg {
            color: #337fff;
          }
          &:hover {
            background: #e5e5e5;
          }
        }
      }
    }
  }
`;

export default WidgetLogin;
